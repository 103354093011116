<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="this.api"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="px-3 pt-3 mb-7" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar color="secondary white--text text-h5">
                        Assign Task
                    </v-toolbar>    
                    <div class="pa-3">
                        <v-text-field class="mt-5" outlined dense v-model="companyName" label="Company" disabled>

                        </v-text-field>
                        <v-form>
                            <!-- <v-text-field ref="name" class="mt-5" outlined dense v-model="form.name" label="Name" :rules="rules">


                            </v-text-field> -->
                            <v-select @change="checkAssignTask" class="mt-5" :items="assignTaskOption" v-model="assignTask" label="Assign Task to..." dense outlined>

                            </v-select>

                            <v-autocomplete label="Department..." dense outlined class="mt-5" v-if="isAssignRole" :rules="rules" item-text="name" v-model="form.role" return-object :items="AssignRoleOption">

                            </v-autocomplete>

                            <v-autocomplete  class="mt-5" v-if="isAssignPIC" :rules="rules" v-model="form.pic" item-text="staff_name"  return-object :items="picOption"  
                            ref="pic"
                            solid outlined rv-model="form.pic" label="PIC..." 
                            :menu-props="{bottom:true,offsetY:true}">
                            </v-autocomplete>

                            <v-autocomplete ref="priority" :rules="rules" dense outlined v-model="form.priority" label="Priority" :items="priorityOption">
                            </v-autocomplete>
                            <v-autocomplete :rules="rules" ref="category" dense outlined  v-model="form.category" label="Category" :items="categoryOption" @change="checkCategoryOptions">
                            </v-autocomplete>

                            <v-text-field v-model="form.otherCategory" dense outlined label="Category" v-if="this.categoryIsOthers">

                            </v-text-field>

                            <!-- <v-text-field v-model="this.form.deadline" label="Deadline" dense outlined @click="openDeadlineCalendar">

                            </v-text-field> -->

                            <v-dialog @click:outside="resetDeadline" v-model="isDeadline" width="auto" height="auto">
                                <div class="d-flex justify-center">
                                    <v-card class="pa-3">
                                        <p>
                                            Task deadline :
                                        </p>
                                        <div>
                                            <v-date-picker v-model="form.deadline" :landscape="true" :reactive="true">

                                            </v-date-picker>
                                        </div>
                                        <div name="footer">
                                            <div class="d-flex justify-end my-3">
                                                <v-btn color="primary" plain @click=resetDeadline>
                                                    Cancel
                                                </v-btn>
                                                <v-btn color="primary" @click="confirmDeadline">
                                                    Confirm
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </div>
                            </v-dialog>

                            <v-textarea filled dense v-model="form.action" placeholder="Description..." solo :counter="500" :rules="rulesMaxLength">

                            </v-textarea>
                            <!-- <mavon-editor
                            style="z-index:0;height:auto"
                            :rules="rules"
                            defaultOpen = "edit"
                            :toolbarsFlag = "false"
                            language="en"
                            v-model="form.action"
                            placeholder="Description...">
                            </mavon-editor> -->

                    
                            <!-- <v-checkbox class="pt-0 mt-0" v-model="form.notify" label="Do you want to send the notification to the staff assigned ?">

                            </v-checkbox> -->
                        
                            <div class="d-flex justify-end mt-3">
                                <v-btn color="primary" plain @click="redirectBack">
                                    Cancel
                                </v-btn>
                                <v-btn color="primary" @click="submit" :loading="api.isLoading" >
                                    Submit
                                </v-btn>
                            </div>
                        </v-form>
                    </div>
                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
// import mavonEditor from 'mavon-editor';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    // "mavon-editor": mavonEditor.mavonEditor,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        rulesMaxLength:[v=>v.length<=500||'Max 500 Characters'],
        rules:[
            cannotEmpty=>!!cannotEmpty||'This field is required',  
        ],
        isDeadline:false,
        valid:false,
        companyName:null,
        isPending:false,
        isAssignRole:false,
        isAssignPIC:false,
        assignTask:null,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        },
        form:{
            name:null,
            category:null,
            otherCategory:null,
            action:null,
            notify:null,
            pic:null,
            role:null,
            deadline:null,
        },
        categoryIsOthers:false,
        picOption:null,
        assignTaskOption:[
            'Department','PIC'
        ],
        AssignRoleOption:[
            {
                'name':'Admin',
                'value':'10'
            },
            {
                'name':'Sales',
                'value':'7',
            },
            {
                'name':'Customer Service',
                'value':'8',
            },
            {
                'name':'Sales Team Leader',
                'value':'9',
            },
            {
                'name':'Sales Manager',
                'value':'12',
            },
            {
                'name':'Operation',
                'value':'11'
            },
        ],
        categoryOption:[
            'Renewal','Check Payment Status With Financial Department','Payment Failed','Check Credit Card Auto Deduct','Others'
        ],
        priorityOption:[
            'Low','Medium','Urgent'
        ]
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getCompanyPIC"){
                this.companyName = resp.data.company.name;
                this.picOption = resp.data.pic;
            }
            if(resp.class=='manualSaveTask'){

                this.api.isSuccesful = true;
                this.api.success = "Succesfully assigned a new task"
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchCompaniesPICApi = this.fetchCompaniesPIC();
            this.$api.fetch(fetchCompaniesPICApi);
        },
        fetchCompaniesPIC(){
            let companyId = this.$router.history.current.params.id; 
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/pic/company/"+companyId;
            return tempApi;
        },  
        validateInput(){
            // if(this.form.name==null){
            //     this.$refs.name.focus();
            //     return false;
            // }
            if(this.form.action>500){
                this.$refs.action.focus();

                return false;
            }
            if(this.form.category==null){
                this.$refs.category.focus();
                return false;
            }
            if(this.form.priority==null){
                this.$refs.priority.focus();
                return false;
            }
            if(this.form.action==null){
                this.$refs.action.focus();
                return false;
            }
            if(this.form.pic==null){
                this.$refs.pic.focus();
                return false;
            }
            return true;
            // this.isPending = true;
        },
        submit()
        {
            // this.valid = this.validateInput();
            // if(this.valid){
            // }
            if(this.form.action.length>500){
                return 0;
            }
            let createApi = this.create();
            this.$api.fetch(createApi);
            // this.isPending = false;
            
        },
        checkCategoryOptions(){
            this.categoryIsOthers = false;
            this.form.otherCategory = null;
            if(this.form.category=='Others'){
                this.categoryIsOthers = true;
            }

        },
        redirectBack(){
          this.$router.go(-1);  
        },
        openDeadlineCalendar(){
            this.isDeadline=true;
        },
        cancelSubmit(){
            this.isPending = true;
        },
        resetDeadline(){
            this.form.deadline=null;
            this.isDeadline = false;
        },
        confirmDeadline(){
            this.isDeadline = false;
        },
        checkAssignTask(){
            this.isAssignPIC = false;
            this.isAssignRole = false;
            if(this.assignTask=='Department'){
                this.isAssignRole = true;
            }
            if(this.assignTask=='PIC'){
                this.isAssignPIC = true;
            }
            
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/manual";
            let formData = new FormData;
            formData.append('companyId',this.$router.history.current.params.id);
            formData.append('userId',this.$store.getters.getUserId);
            // this.form.pic.forEach(pic => {
            //     formData.append('pic[]',pic.pic_id);
            // });
            if(this.isAssignPIC){
                formData.append('pic',this.form.pic.pic_id);

            }
            if(this.isAssignRole){
                formData.append('role',this.form.role.value);
            }
            formData.append('name',this.form.name);
               
            if(this.categoryIsOthers && this.form.otherCategory!=null){
                formData.append('category',this.form.otherCategory);
            }
            else{
                formData.append('category',this.form.category);
            }
            formData.append('action',(this.form.action)?this.form.action:"No description");
            formData.append('priority',this.form.priority);
            formData.append('notify',true);
            // if(this.form.deadline!=null){
            //     formData.append('deadline_at',this.form.deadline);
            // }
            // if(this.form.notify==true){
            //     formData.append('notify',true);
            // }
            tempApi.params = formData;
            return tempApi;
            // formData.append('userId',)
        },
    }
}
</script>